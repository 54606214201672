import FuboLogo from './logo.svg';
import SBLogo from './sportsbook.svg';

type LogoProps = {
    size?: '1' | '2' | '3' | '4' | '5'
}

export default function Logo(props: LogoProps) {
    const size = Number(props.size) || 4
    return (
        <div className="Logo">
            <img
                src={FuboLogo}
                alt="Fubo Logo"
                height={12 * size}
                width={30 * size}
            />
            <div className="h-0" />
            <img 
                src={SBLogo} 
                alt="SB Logo"
                width={30 * size} 
                height={3 * size} 
            />
        </div>
    );
};