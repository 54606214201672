import './Footer.css';
import {ValidStates} from '../App';

type FooterProps = {
    state: ValidStates,
}



export default function Footer({state}: FooterProps) {
    const year = new Date().getFullYear();
    return (
        <div className="footer">
            <span>
                Gambling Problem? Call {" "}
                <strong>
                    <a href="tel:800-426-2537">1-800-GAMBLER {" "}</a>
                </strong>
            </span>
            <span>
                © {year} Fubo Gaming Inc. Sportsbook users must be over 21 or older. 
            </span>
            <span>
                <a href={`https://${state}.fubosportsbook.com/en-us/privacypolicy`}>
                    Privacy Policy
                </a>
                {" "} | {" "}
                <a href={`https://${state}.fubosportsbook.com/en-us/termsandconditions`}>
                    Terms of Use
                </a>
            </span>
        </div>
    );
};