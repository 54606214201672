import Logo from './Logo/Logo';
import './FormContainer.css';
import { ValidStates } from '../App';
import QForm from './QForm/QForm';

type FormContainerProps = {
    state: ValidStates,
}

export default function FormContainer({ state }: FormContainerProps) {
    return (
        <div className="FormContainer">
            <Logo size="5"/>
            <QForm state={state} />
        </div>
    );
};