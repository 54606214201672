import React from 'react';
import FormContainer from './FormContainer/FormContainer';
import Footer from './Footer/Footer';
import './App.css';

export enum ValidStates {
  nj = 'nj',
}
const defaultState = ValidStates.nj;

const getStateFromPath = (): ValidStates => {
  const pathItems = window.location.pathname.split("/");

  // Guess that the first / is immediately followed by a state code
  // or default to defaultState
  const stateInPath = (pathItems.length === 2) ? pathItems[1] : defaultState;

  switch (stateInPath) {
    case ValidStates.nj:
      return ValidStates.nj;
    default:
      return defaultState;
  }
};

function App() {
  const state = getStateFromPath();
  return (
    <div className="App">
      <FormContainer state={state}/>
      <Footer state={state}/>
    </div>
  );
}

export default App;
