import { ValidStates } from '../../App';

type QFormProps = {
    state: ValidStates,
}

export default function QForm({state}: QFormProps) {
    return (
        <div> Quatrix form goes here! </div>
    );
};